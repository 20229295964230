import React from 'react';
import openQuote from "../../../assets/images/GiantOpenQuote.svg";
import closeQuote from "../../../assets/images/GiantCloseQuote.svg";
import './NewHomeTabView.css'

const Testimonial = (props) => {
    return (
        <div className={"first-testimonial"}>
            <div className={"left-of-testimonial"}>
                <img src={props.teamLogo} alt="TeamPacmanLogo" className={"team-logo"}/>
                <h1 className={"team-name"}>{props.teamName}</h1>
                <div className={"testimonial-description"}>
                    {props.descriptionLines.map(line =>
                        <h4 className={"description-first"}>{line}</h4>)
                    }
                </div>
            </div>
            <div className={"right-of-testimonial"}>
                <img src={openQuote} alt={"OpenQuote"} className={"open-quote"}/>
                <h2>{props.topQuote}</h2>
                <p className={"right-side-description"}>{props.bottomQuote}</p>
                <img src={closeQuote} alt={"CloseQuote"} className={"close-quote"}/>
            </div>
        </div>
    );
};

export default Testimonial;
