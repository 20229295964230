import React from 'react';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import './ProductPages.css';
import img from "../../assets/images/BenefitStar.svg";
import GithubMarkdown from "../github-markdown/GithubMarkdown";
import ProductTabIndexService from "../../services/ProductTabIndexService";
import {useHistory} from "react-router";

function SplunkOnCallTabView(props) {

    const history = useHistory();

    return (
        <div className={"tabs-outer"}>
            <Tabs className={"tabs"} defaultIndex={ProductTabIndexService.getIndexOfTabToDisplay(props.tab)}>
                <TabList className={"tab-list"}>
                    <Tab className={"tab"} onClick={() => history.push("/splunk-oncall/why")}><h2>Why use Splunk On-Call?</h2></Tab>
                    <Tab className={"tab"} onClick={() => history.push("/splunk-oncall/setup")}><h2>Setting up Splunk On-Call</h2></Tab>
                </TabList>

                <div className={"tab-content"}>
                    <TabPanel className={"spotlight-tab"}>
                        <h2><img src={img} alt={"BenefitStar"}/> On-Call Schedule</h2>
                        <ul>
                            <li>- Set up a rotating call schedule to quickly respond to incidents generated by your
                                automatic alerts
                            </li>
                            <li>- Motivates the team to prioritize improved alerting and incident resolution
                                capabilities
                            </li>
                        </ul>
                        <h2><img src={img} alt={"BenefitStar"}/> Built in Post-Mortems</h2>
                        <ul>
                            <li>- Link incidents and events to post-mortems for future reference</li>
                        </ul>
                        <h2><img src={img} alt={"BenefitStar"}/> Integration with HealthNut + SRE Metrics</h2>
                        <ul>
                            <li> - Get alerts from HealthNut when your monitoring services go down</li>
                            <li> - Get alerts from SRE Metrics when your observability metrics exceed a threshold</li>
                        </ul>
                        <h2><img src={img} alt={"BenefitStar"}/> Fastest Way to get Services back online when a human
                            needs to be involved</h2>
                        <ul>
                            <li>- Get out in front of the problem by leveraging automated alerts to resolve the incident
                                before your users alert you
                            </li>
                        </ul>
                        <h2><img src={img} alt={"BenefitStar"}/> Be Notified on your Mobile Devices</h2>
                        <ul>
                            <li>- Get a call or text from Splunk On-Call to ensure you actually receive the alert</li>
                        </ul>
                    </TabPanel>

                    <TabPanel>
                        <GithubMarkdown fileName={"SplunkOncall"} viewInTabView={true}/>
                    </TabPanel>
                </div>
            </Tabs>
        </div>
    );
}

export default SplunkOnCallTabView
