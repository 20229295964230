import {useMatomo} from "@datapunt/matomo-tracker-react";
import React, {cloneElement} from "react";
import './ProductPages.css';
import TileButton from "../NewLandingPage/Tiles/TileButton";
import PropTypes from "prop-types";
import {useParams} from "react-router";

function ProductPage(props) {
    const {trackPageView, pushInstruction} = useMatomo();
    const anyPropIsMissing = props.pageValue === undefined || props.title === undefined ||
        props.subtitle === undefined || props.buttonText === undefined || props.tabView === undefined || props.link === undefined;

    trackPageView({
            documentTitle: props.pageValue,
            href: window.location.href
        }
    )
    pushInstruction('setUserId', props.cdsid)

    const {product = "metrics", tab = "why"} = useParams();

    return (
        <div className="Product-Pages" id="outer-container-product-page">

            {anyPropIsMissing ? <>
                    <p className={"title"} style={{margin: "0 auto"}}>
                        Oops something went wrong!
                    </p>
                    <br/>
                </>
                :
                <>
                    <h1 className={"title"}>{props.title}</h1>
                    <br/>
                    <h4 className={"subtitle"}>{props.subtitle}</h4>

                    <div className={"goto-button"}>
                        <TileButton style={{display: "block", margin: "0 auto"}} text={props.buttonText}
                                    href={props.link}/>
                    </div>
                    {cloneElement(props.tabView, {product: product, tab: tab} )}
                </>}
        </div>
    );
}

ProductPage.propTypes = {
    pageValue: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    tabView: PropTypes.object.isRequired
};
export default ProductPage

