import React from 'react';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import pacmanLogo from "../../../assets/logos/TeamPacmanLogo.svg";
import spyroLogo from "../../../assets/logos/TeamSpyroLogo.svg";
import './NewHomeTabView.css'
import Testimonial from "./Testimonial";
import TileButton from "../Tiles/TileButton";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import YouTube from "react-youtube";


const NewHomeTabView = (props) => {
    const {trackEvent} = useMatomo();

    function handleDegreedClick() {
        trackEvent({category: 'button-click', action: 'degreed-pathway-button'})
    }

    function handleVideoClick(action) {
        console.log("handling video click")
        trackEvent({category: 'video', action: action})
    }

    return (
        <div className={"tabs-outer homepage-tabs-outer"}>
            <Tabs className={"homepage-tabs"}>
                <TabList className={"tab-list"}>
                    <Tab className={"tab"}><h2>What is SRE?</h2></Tab>
                    <Tab className={"tab"}><h2>Testimonials</h2></Tab>
                </TabList>

                <div className={"homepage-tab-content"}>
                    <TabPanel>
                        <p className={"sre-session"}>Site Reliability Engineering exists with the goal of helping you
                            improve your users’ experience
                            with your application or service. That includes things like viewing real time availability
                            and latency,
                            being notified when your service goes down before your users notice, and managing incidents
                            when they occur.</p>

                        <YouTube videoId={"uTEL8Ff1Zvk"} onPlay={() => handleVideoClick('sre-devops-video-start')} onEnd={() => handleVideoClick('sre-devops-video-end')} className={"video-styling"}/>
                        <p className={"degreed-session"}>To learn more, check out our SRE Degreed Learning Pathway.</p>
                        <TileButton className={"degreed-button"} text={"Learn More"}
                                    href={"https://degreed.com/pathway/v837gy739x?path=site-reliability-engineering--sre-"}
                                    onClick={handleDegreedClick}/>
                    </TabPanel>
                    <TabPanel className={"testimonial-tab"}>
                        <Testimonial
                            teamLogo={pacmanLogo}
                            teamName={"TEAM PACMAN"}
                            descriptionLines={["Real Time Traffic (RTT)", "Local Hazard Information (LHI)"]}
                            topQuote={`The SRE tools are intuitive and easy-to-use while the team provides great guidance 
                            via slack channel and office hours...`}
                            bottomQuote={`The SRE team supported us in setting up our own Grafana Org with alerts 
                            pushing to Slack and VictorOps in case of an availability issue. Furthermore we utilized
                             the HealthNut page to setup alerts.`}
                        />
                        <Testimonial
                            teamLogo={spyroLogo}
                            teamName={"TEAM SPYRO"}
                            descriptionLines={["Power My Trip"]}
                            topQuote={`Integration into a new service is easy with the SRE library...`}
                            bottomQuote={`The 4 Golden Signals, error rate dashboard, Health Checks, and custom alerts are invaluable to the team.`}
                        />
                    </TabPanel>
                </div>
            </Tabs>
        </div>
    );
};


export default NewHomeTabView;