import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import './NewHeader.css';
import sreLogo from "../../../assets/logos/SRELogoOnDarkOutlines.svg";
import NavBarMenu from "../NavBarMenu/NavBarMenu";
export default function NewHeader(props) {

    return (
        <div className={"new-header-content"}>
            <AppBar id={"new-header"} className={"new-app-header"} position="static" style={{backgroundColor: '#26282F'}}>
                <Toolbar>
                    <div className={"left-header"}>
                        <a href={"/"} data-testid={"title"} className={"new-title-link"}>
                            <img src={sreLogo} alt={"Sre-Logo"} className={"new-sre-logo"} />
                            <h1 className={"new-header"}>Site Reliability Engineering</h1>
                        </a>
                    </div>
                    <div className={"right-header"}>
                        <p className={"cds-id"}>{`Welcome, ${props.cdsid}`}</p>
                        <NavBarMenu/>
                    </div>

                </Toolbar>
            </AppBar>
        </div>
    );
}
