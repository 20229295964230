import {push as Menu} from "react-burger-menu";
import React from "react";
import ReactGA from "react-ga";
import '../App.css';
import Grid from "@material-ui/core/Grid";
import CollapsibleMenu from "./CollapsibleMenu";

function sendAnalytics(description) {
    ReactGA.event({category: "Hamburger Menu", action: description})
}

export default function HamburgerMenu(props) {

    const observabilityLinks = [
        {text: "Go to the Observability Platform", href: "https://observability.sre.ford.com/d/d8wPQh7Mk/observability-homepage?orgId=1"},
        {text: "Go to SRE Metrics", href: "https://observability.sre.ford.com/d/dx25mo7Mk/application-dashboards?orgId=1"}
    ]
    const observabilitySetupLinks = [
        {text: "Generate application Metrics", href: "sre-metrics#GenerateMetrics"},
        {text: "Application Configuration", href: "sre-metrics#ApplicationConfiguration"},
        {text: "Running in OpenShift / Kubernetes", href: "sre-metrics#RunningInOpenshift"},
        {text: "What's Next?", href: "sre-metrics#NextSteps"}
    ]
    const alertingLinks = [{text: "Go to HealthNut", href: "https://healthnut.ford.com"}];
    const incidentResponseLinks = [{text: "Go to Victor-Ops", href: "https://portal.victorops.com/client/ford"}]
    const routingKeysLinks = [
        {text: "Setup Splunk On-Call", href: "victor-ops#SettingUpSplunk"},
        {text: "Add Team Members", href: "victor-ops#AddTeamMembers"},
        {text: "Add Routing Keys", href: "victor-ops#AddRoutingKeys"},
        {text: "Logging In", href: "victor-ops#LoggingIn"}
    ]
    const communicationLinks = [{href: "https://advisory.sre.ford.com", text: "Go to FAM"}]

    return (
        <div onClick={() => sendAnalytics("Hamburger Yum")}>

            <Grid className={'buttons'}
                  direction="column"
                  justify="space-evenly"
                  alignItems="stretch">

                <Menu pageWrapId={props.pageWrapId} outerContainerId={props.outerContainerId}>
                    <a id="home" className="menu-item" href="/">Home</a>

                    <br/>

                    <div className="CollapseMenu">
                        <CollapsibleMenu title={"Observability >"} links={observabilityLinks}>
                            <CollapsibleMenu title={"Learn more about setup >"} links={observabilitySetupLinks}/>
                        </CollapsibleMenu>

                        <CollapsibleMenu title={"Alerting >"} links={alertingLinks} />

                        <CollapsibleMenu title={"Incident Response >"} links={incidentResponseLinks}>
                            <CollapsibleMenu title={"Learn more about Routing Keys >"} links={routingKeysLinks}/>
                        </CollapsibleMenu>

                        <CollapsibleMenu title={"Communication >"} links={communicationLinks} />
                    </div>
                </Menu>
            </Grid>

        </div>
    );
}
