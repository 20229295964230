import React from 'react';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import './ProductPages.css';
import img from "../../assets/images/BenefitStar.svg";
import ProductTabIndexService from "../../services/ProductTabIndexService";
import {useHistory} from "react-router";
import TileButton from "../NewLandingPage/Tiles/TileButton";


function FamTabView(props) {

    const history = useHistory();

    return (
        <div className={"tabs-outer"}>
            <br />
            <TileButton style={{display: "block", margin: "0 auto"}} text={"IT Advisory Tool Support Information"}
                                    href={"https://ford-dwp.onbmc.com/dwp/app/#/knowledge/KBA00518478/rkm"}/>
            <br />
            <br />
            <TileButton style={{display: "block", margin: "0 auto"}} text={"Go to FAM"}
                                    href={"https://advisory.sre.ford.com"}/>
            <br />
            <br />

        </div>
    );
}

export default FamTabView