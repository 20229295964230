import React from 'react';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import './ProductPages.css';
import img from "../../assets/images/BenefitStar.svg";
import ProductTabIndexService from "../../services/ProductTabIndexService";
import {useHistory} from "react-router";


function SLOTabView(props) {

    const history = useHistory();

    return (
        <div className={"tabs-outer"}>
            <Tabs className={"tabs"} defaultIndex={ProductTabIndexService.getIndexOfTabToDisplay(props.tab)}>
                <TabList className={"tab-list"}>
                    <Tab className={"tab"} onClick={() => history.push("/slo/why")}><h2>Why set a SLO/SLI?</h2></Tab>
                    <Tab className={"tab"} onClick={() => history.push("/slo/setup")}><h2>How to setup a SLI</h2></Tab>
                </TabList>

                <div className={"tab-content"}>
                    <TabPanel className={"spotlight-tab"}>
                        <h2><img src={img} alt={"BenefitStar"}/> Why reliability is important</h2>
                        <ul>
                            <li>- "You could have the best app in the world, but if it isn't up it doesn't matter. Serve your users with the best app possible" -Caleb Phillips</li>
                            <li>- You can measure your service's reliability with service level indicators (SLIs) and measure user satisfaction with service level objectives (SLOs) </li>
                            <li>- By defining and measuring SLIs and SLOs, you can prioritize resiliency features only when they are valuable to your users </li>
                        </ul>
                        <h2><img src={img} alt={"BenefitStar"}/>SLIs</h2>
                        <ul>
                            <li>- A service level indicator (SLI) is a quantitative measure of some aspect of the level of service that is provided to your users</li>
                            <li>- Example: Number of non 500 http responses divided by the total number of responses</li>
                            <li>- These measurements are usually an aggregation over a short period of time such as a rate, average, or percentile</li>
                        </ul>
                        <h2><img src={img} alt={"BenefitStar"}/>SLOs</h2>
                        <ul>
                            <li>- A service level objective (SLO) is a threshold for a single or set of SLIs that if not met, will result in user frustration</li>
                            <li>- If no user frustration occurs, or occurs before your SLO is breached, you will need to adjust your SLO accordingly </li>
                            <li>- Example: SLO for uptime: 99.999% over a 28 day rolling window</li>
                        </ul>
                        <h2><img src={img} alt={"BenefitStar"}/>Summary</h2>
                        <ul>
                            <li>- SLI: Quantitative measure of the current reliability of your application</li>
                            <li>- SLO: Quantitative limit of your SLIs that is acceptable to your users</li>
                        </ul>
                    </TabPanel>

                    <TabPanel className={"spotlight-tab"}>
                        <br/>
                        <div style={{textAlign: "center"}}>
                        <h3>More information coming soon!</h3>
                        </div>
                        <br/>
                    </TabPanel>

                </div>
            </Tabs>
        </div>
    );
}

export default SLOTabView