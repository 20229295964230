import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import './AlertHeader.css';
import sreLogo from "../../../assets/logos/SRELogoOnDarkOutlines.svg";
import NavBarMenu from "../NavBarMenu/NavBarMenu";
import { Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
export default function Alertheader(props) {

    return (
        <div className={"new-header-content"} style={{paddingTop: '25px'}}>
            <AppBar id={"new-header"} className={"new-app-header"} position="static" style={{backgroundColor: '#26282F'}}>
                <Toolbar style={{ justifyContent: 'center' }}>
                    <Alert severity={props.severity} className='center-header' style={{ justifyContent: 'center' }}>{props.text}</Alert>
                </Toolbar>
            </AppBar>
        </div>
    );
}
