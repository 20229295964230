const possibleTabs = Object.freeze(["why", "setup", "migration"]);
const possibleProducts = Object.freeze(["metrics", "logging", "tracing", "grafana-tutorials"]);

function getIndexOfTabToDisplay(tabToDisplay) {
    return possibleTabs.indexOf(tabToDisplay?.toLowerCase());
}

function getIndexOfProductToDisplay(productToDisplay) {
    return possibleProducts.indexOf(productToDisplay?.toLowerCase());
}

const ProductTabIndexService = {
    getIndexOfTabToDisplay,
    possibleTabs,
    getIndexOfProductToDisplay,
    possibleProducts
};


export default ProductTabIndexService;