import React from 'react';
import TileButton from "./TileButton";
import externalLink from "../../../assets/logos/externalLink.svg";
import verboseStar from "../../../assets/logos/starthree.svg";
import "./Tile.css";

const Tile = (props) => {
    return (
        <div className={"tile"}>
            <h2 className={"tile-title"}>{props.title}</h2>
            <h4 className={"tile-subtitle"}>{props.subtitle}</h4>
            <TileButton className={"tile-button-in-tile"} text={props.buttonText} href={props.buttonHref}/>
            <div  className={"tile-link"}>
                <a href={props.linkHref} rel={"noopener noreferrer"} target={"_blank"}>{props.linkText}</a>
                {/* <img src={props.verboseMode ? verboseStar : externalLink} alt={"Tile Link"} className={props.verboseMode ? "verbose-link" : "external-link"} /> */}
            </div>
        </div>
    );
};

export default Tile;