import React, {Component} from "react";
import ReactMarkdown from "react-markdown/with-html";
import '../OnboardingDocs.css'
import '../../App.css';
import raw from "raw.macro";
import htmlParser from 'react-markdown/plugins/html-parser';
import LinkRenderer from "../../LinkRenderer";
import * as remarkGfm from "remark-gfm";
const emoji = require('node-emoji')

const parseHtml = htmlParser({
    isValidNode: (node) => node.type !== 'script',
    processingInstructions: []
})


export default class GithubMarkdown extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        document.title = this.props.fileName
    }
    getCssClass(){
        return this.props.viewInTabView === true ? "embedded-markdownBody" : "markdownBody"
    }

    render() {
        const fileName = this.props.fileName

        return (
            <div id={"outer-container-github"}>
                <div id={"page-wrap-github"} className={this.getCssClass()}>
                    <p>
                        <ReactMarkdown
                            astPlugins={[parseHtml]}
                            allowDangerousHtml={true}
                            children={emoji.emojify(raw(`../../docs/${fileName}.md`))}
                            transformImageUri={this.replaceGithubWithLocalUrl}
                            transformLinkUri={this.removeMarkdownExtensionAndRedirect}
                            renderers={{link: LinkRenderer.renderLinks}}
                            plugins={[remarkGfm]}
                        />
                    </p>
                </div>
            </div>
        )
    };

    replaceGithubWithLocalUrl(uri) {
        if (uri.startsWith("https://github.ford.com/Platform-Enablement/sre_metrics/blob/master")) {
            return uri.replace('https://github.ford.com/Platform-Enablement/sre_metrics/blob/master/docs/images', `${window.location.origin}/images/dashboardTutorial`)
        } else if(uri.startsWith("https://github.ford.com/Platform-Enablement/product-onboarding/blob/master")) {
            return uri.replace('https://github.ford.com/Platform-Enablement/product-onboarding/blob/master', window.location.origin)
        } else {
            return uri
        }
    }

    removeMarkdownExtensionAndRedirect(uri) {
        let uriToUse = uri.startsWith(window.location.origin) ? uri.replace(window.location.origin, "") : uri
        uriToUse = uri.endsWith("victorops.html") ? "victor-ops" : uriToUse;
        uriToUse = uri.includes("setup.md") ? uri.replace("setup.md", "setup") : uriToUse;
        uriToUse = uriToUse.includes(".md") && !uriToUse.startsWith("http") ? uriToUse.replace(".md", "") : uriToUse;
        return uriToUse
    }

}

GithubMarkdown.propTypes = {
    viewInTabView: Boolean
}
GithubMarkdown.defaultProps = {
    viewInTabView: false
}
