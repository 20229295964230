import React from "react";
import LinkRenderer from "../../../LinkRenderer";

export default function SreHomepageLink(props) {
    return (
        <div className={props.className}>
            {LinkRenderer.renderLinks({href: props.href, children: props.children})}
        </div>
    );
}

