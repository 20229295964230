import React, {useState} from 'react';
import "./Feedback.css";
import sreHomeFeedbackImg from "../../assets/logos/SREHomeFeedbackButton.svg";
import healthNutFeedbackImg from "../../assets/logos/HealthNutFeedbackButton.svg";
import topFeedbackImg from "../../assets/logos/TOPFeedbackButton.svg";
import famFeedbackImg from "../../assets/logos/FAMFeedbackButton.svg";
import webexImg from "../../assets/logos/webex.svg";
import slackImg from "../../assets/logos/slack.png";
import StyleButton from "../Buttons/StyleButton";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ReactStars from "react-rating-stars-component/dist/react-stars";
import {useMatomo} from "@datapunt/matomo-tracker-react";

const Feedback = (props) => {
    const {trackPageView, pushInstruction} = useMatomo();
    // Track page view
    trackPageView({
            documentTitle: 'SRE Feedback', // optional
            href: 'https://sre.ford.com/feedback', // optional
            customDimensions: [
                {
                    id: 1,
                    value: 'loggedIn',
                },
            ],
        }
    )
    pushInstruction('setUserId', props.cdsid)

    const [products] = useState([
        {img: sreHomeFeedbackImg, alt: "Sre Homepage"},
        {img: healthNutFeedbackImg, alt: "HealthNut"},
        {img: topFeedbackImg, alt: "The Observability Platform"},
        {img: famFeedbackImg, alt: "Ford Advisory Management"}
    ])
    const [activeIndex, setActiveIndex] = useState(-1)
    const [starsRated, setStarsRated] = useState(0)
    const [feedbackMessage, setFeedbackMessage] = useState("")
    const [popupOpen, setPopupOpen] = useState(false);
    const [popUpText, setPopUpText] = useState("")

    const renderProducts = () => {
        return products.map((product, index) => {
            const className = activeIndex === index ? 'tool-image active' : 'tool-image';
            return <img src={product.img} alt={product.alt} className={className}
                        onClick={() => handleProductSelection(index)}/>
        })
    }

    function handleFeedbackMessageChange(event) {
        setFeedbackMessage(event.target.value)
    }

    function handleProductSelection(index) {
        setActiveIndex(index)
    }

    function requiredFieldsCompleted(){
        if(activeIndex === -1){
            return "Please select a product"
        }else if(starsRated === 0){
            return "Please select a rating"
        }
        return "Success"
    }

    const submitFeedback = () => {
        const productSelected = products[activeIndex]?.alt
        let checkAllFieldsSelected = requiredFieldsCompleted()
        checkAllFieldsSelected === "Success"
            ?
            props.feedbackService.submitFeedbackToApi(props.cdsid, productSelected, feedbackMessage, starsRated)
                .then(() => {
                    showSuccessMessage();
                }).catch(() => {
                showFailureMessage("Sorry, we ran into an issue when submitting your feedback. Please try again!");
            })
            : showFailureMessage(checkAllFieldsSelected);
    }

    function showSuccessMessage() {
        setPopUpText("Your feedback has been submitted!")
        setPopupOpen(true)
        setFeedbackMessage("")
        setActiveIndex(-1)
        setStarsRated(0)
    }

    function showFailureMessage(failedReason) {
        setPopUpText(failedReason ?? "Sorry, we ran into an issue when submitting your feedback. Please try again!")
        setPopupOpen(true)
    }

    return (
        <div className={"feedback-container"}>
            <div className={"inside-container"}>
                <h2 className={"feedback-subtitle"}>Please share your Feedback about the SRE tools!</h2>
                <h2 className={"subtext"}>The SRE community channels are here to help.</h2>
                <div className={"feedback-links"}>
                    <img src={slackImg} alt={"Feedback"} className={"link-image"}/>
                    <a href={"https://ford-eeit-cc.slack.com/archives/CM9E024P6"} rel={"noopener noreferrer"} target={"_blank"}
                       className={"link"}> Join the SRE Slack
                        Channel </a>
                </div>
                <div className={"feedback-links"}>
                    <img src={webexImg} alt={"Feedback"} className={"link-image"}/>
                    <a href={"https://www.webexteams.ford.com/publicspaceindex"} rel={"noopener noreferrer"} target={"_blank"} className={"link"}>Join
                        the Webex SRE Teams Channel</a> <br/>
                </div>
                <br/>
                <div>
                    <p className={"indicates-required"}>* indicates required field</p>
                </div>

                <h2 className={"feedback-subtitle"}>Please indicate which of our tools you'd like to share feedback
                    about: <b className={"indicates-required"}>*</b></h2>
                <div className={"tool-styling"}>
                    {renderProducts()}
                </div>
                <br/>
                <h2 className={"feedback-subtitle"}>How likely are you to recommend this tool? <b className={"indicates-required"}>*</b></h2>
                <div className={"feedback-stars-div"}>
                    <ReactStars
                        key={starsRated}
                        count={5}
                        onChange={setStarsRated}
                        size={80}
                        value={starsRated}
                        activeColor="#12d5d7"
                    />
                </div>

                <h2 className={"feedback-subtitle"}>Please share your thoughts and feedback about this tool:</h2>
                <div className={"feedback-textarea-container"}>
                    <textarea
                        name="textValue"
                        onChange={handleFeedbackMessageChange}
                        className={"feedback-textarea"}
                        maxLength={500}
                        required={true}
                        wrap={"hard"}
                        aria-label={"feedback-text"}
                        value={feedbackMessage}
                    />
                </div>
                <br/>
                <div className={"feedback-button"}>
                    <StyleButton text={"Send to the SRE team"} onClick={submitFeedback}/>
                </div>
                <Popup open={popupOpen} onClose={() => setPopupOpen(false)} position="right center">
                    <div className={"popup"}>{popUpText}</div>
                </Popup>
            </div>

        </div>
    );
};

export default Feedback;
