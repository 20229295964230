import axios from "axios";
import {constructConfig} from "../config";


const submitFeedbackToApi =async (cdsid, tool, feedbackMessage, starRating) => {
    let requestBody = {
        cdsId: cdsid,
        tool: tool,
        feedbackMessage: feedbackMessage,
        starRating: starRating
    };
    const config = constructConfig(window.location.hostname)
    return await axios.post(`${config.baseApiUrl}/api/feedback`, requestBody)
};

const FeedbackService = {
    submitFeedbackToApi,
};


export default FeedbackService;
