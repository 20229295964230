import React from 'react';
import './TileContainer.css';
import Tile from "./Tile";
import DeactivatedTile from "./DeactivatedTile";
import {constructConfig} from "../../../config";

const config = constructConfig(window.location.hostname)

const TileContainer = (props) => {
    return (
        <div className={"tiles-container"}>
            <Tile
                title={"Splunk On-Call"}
                subtitle={"Create an automated and personalized on-call schedule for incident management"}
                buttonText={"Learn More"}
                buttonHref={"/splunk-oncall"}
                linkText={"Go to Splunk On-Call"}
                linkHref={"https://portal.victorops.com/client/ford"}
                verboseMode={props.verboseMode}
            />
            <Tile
                title={"SRE Logging"}
                subtitle={"This application is no longer onbaording and is scheduled for decommission on 9/12."}
                buttonText={"Learn More"}
                buttonHref={"/observability-platform/logging"}
                // linkText={"Add logs to your Grafana Org"}
                // linkHref={"https://orgs.sre.ford.com"}
                // verboseMode={props.verboseMode}
            />
            <Tile
                title={"HealthNut"}
                subtitle={"Get alerted when your app is down before your users "}
                buttonText={"Learn More"}
                buttonHref={"/healthnut"}
                linkText={"Go to HealthNut"}
                linkHref={"https://healthnut.ford.com"}
                verboseMode={props.verboseMode}
            />
            <Tile
                title={"SRE Metrics"}
                subtitle={"This application is no longer onbaording and is scheduled for decommission on 10/30."}
                buttonText={"Learn More"}
                buttonHref={"/observability-platform/metrics"}
                // linkText={"Go to SRE Metrics"}
                // linkHref={"https://observability.sre.ford.com/d/dx25mo7Mk/application-dashboards?orgId=1"}
                // verboseMode={props.verboseMode}
            />
            <Tile
                title={"FAM"}
                subtitle={"Advise your users that your app is down using Ford Advisory Management"}
                buttonText={"Learn More"}
                buttonHref={"/fam"}
                linkText={"Go to FAM"}
                linkHref={"https://advisory.sre.ford.com/"}
                verboseMode={props.verboseMode}
            />
            <DeactivatedTile
                title={"Renovate Randy"}
                subtitle={"This application is no longer offered."}
            />
            <Tile
                title={"SLI/SLO"}
                subtitle={"Take control of your app's reliability with objectives and measurements"}
                buttonText={"Learn More"}
                buttonHref={"/slo"}
                linkText={"Go to SLI/SLO Docs"}
                linkHref={"https://sre.ford.com/slo"}
                verboseMode={props.verboseMode}
            />

            <Tile
                title={"Grafana Tutorials"}
                subtitle={"Step by step instructions on dashboards in grafana"}
                buttonText={"Learn More"}
                buttonHref={"/observability-platform/grafana-tutorials"}
                linkText={"Go to Grafana"}
                linkHref={"https://observability.sre.ford.com/d/dx25mo7Mk/application-dashboards?orgId=1"}
                verboseMode={props.verboseMode}
            />
{/* 
            shows Tracing tile only in local and not in other environments}
            {config.tracing === true ?
                < Tile
                    title={"Tracing"}
                    subtitle={"Tracks the progression of individual requests handled by your application services."}
                    buttonText={"Learn More"}
                    buttonHref={"/observability-platform/tracing"}
                    linkText={"Go to Tracing Docs"}
                    linkHref={"https://github.ford.com/Platform-Enablement/product-onboarding/blob/master/docs/sre_tracing.md"}
                    verboseMode={props.verboseMode}
                />
                : <></>
            } */}
        </div>
    );
};


export default TileContainer;
