import React from 'react';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import './ProductPages.css';
import img from "../../assets/images/BenefitStar.svg";
import ProductTabIndexService from "../../services/ProductTabIndexService";
import {useHistory} from "react-router";


function HealthnutTabView(props) {

    const history = useHistory();

    return (
        <div className={"tabs-outer"}>
            <Tabs className={"tabs"} defaultIndex={ProductTabIndexService.getIndexOfTabToDisplay(props.tab)}>
                <TabList className={"tab-list"}>
                    <Tab className={"tab"} onClick={() => history.push("/healthnut/why")}><h2>Why use HealthNut?</h2></Tab>
                    <Tab className={"tab"} onClick={() => history.push("/healthnut/setup")}><h2>Setting up HealthNut</h2></Tab>
                </TabList>

                <div className={"tab-content"}>
                    <TabPanel className={"spotlight-tab"}>
                        <h2><img src={img} alt={"BenefitStar"}/> Get Alerts When your App is Down</h2>
                        <ul>
                            <li>- Receive Slack or WebEx alerts when your app is down before you users know</li>
                        </ul>
                        <h2><img src={img} alt={"BenefitStar"}/> Get Alerts When your Dependency is Down</h2>
                        <ul>
                            <li>- Reduce time spent in unnecessary triage by monitoring the apps you depend on</li>
                        </ul>
                        <h2><img src={img} alt={"BenefitStar"}/> Easy Access</h2>
                        <ul>
                            <li> - HealthNut can check any endpoint that returns a 200 http code</li>
                        </ul>
                        <h2><img src={img} alt={"BenefitStar"}/> Easily Manage Endpoints</h2>
                        <ul>
                            <li>- View, add or delete all your endpoints in one place from the "Manage" page</li>
                        </ul>
                    </TabPanel>

                    <TabPanel className={"spotlight-tab"}>
                        <h2>Step 1 &nbsp;: Check out the HealthNut homepage by clicking the button above</h2>
                        <br/>
                        <h2>Step 2 : Select the communication channel you want HealthNut alerts on.</h2>
                        <br/>
                        <h2>Step 3 : Register your Endpoint</h2>
                        <ul>
                            <li><h3>Option 1 : If you chose Slack or Victor-Ops, fill out the form with the following
                                information:</h3></li>
                            <br/>
                            <ul>
                                <li>- Endpoint : This is the url that HealthNut will hit to check if it's healthy</li>
                                <br/>
                                <li>- Friendly name : This name is just for you so that you can recognize the endpoints
                                    that you have registered
                                </li>
                                <br/>
                                <li>- Channel/Routing Key : This is where you want to see your alerts (Channel for Slack,
                                    Routing key for Splunk On-Call)
                                </li>
                            </ul>
                            <br/>
                            <li><h3>Option 2 : If you chose WebEx - follow the instructions in the WebEx tab on using
                                our
                                HealthNut bot Integration!</h3></li>
                            <br/>

                        </ul>
                        <h2>Step 4 : Verify your Endpoint</h2>
                        <ul>
                            <ul>
                                <li>- Go to the "Manage" page on HealthNut home</li>
                                <br/>
                                <li>- Enter into the search box one of the fields you filled out earlier (endpoint,
                                    friendly name, or channel/routing key)
                                </li>
                                <br/>
                                <li>- Confirm that your endpoint appears in the list</li>
                                <br/>
                                <li> Note: You can delete your endpoint from the manage page</li>
                                <br/>
                            </ul>
                        </ul>
                    </TabPanel>

                </div>
            </Tabs>
        </div>
    );
}

export default HealthnutTabView