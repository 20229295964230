import getAdfsUrl from "./config.js";

export default class UserAuthenticationHandler {
    constructor() {
        const adfsUrl = getAdfsUrl(window.location.hostname);
        let redirectToUrl = window.location.href

        if (redirectToUrl.startsWith("http://") && !redirectToUrl.includes("http://localhost")) {
            redirectToUrl = redirectToUrl.replace("http://", "https://");
        }

        this.redirectUrl = adfsUrl + redirectToUrl
    }

    redirect() {
        window.location.href = this.redirectUrl;
    }

    getToken() {
        const tokenFromURL = this.getTokenFromURL();
        if (tokenFromURL) {
            sessionStorage.setItem("token", tokenFromURL);
            return tokenFromURL
        }
        return sessionStorage.getItem("token");
    }

    getTokenFromURL() {
        const url = window.location.href;
        const match = url ? url.match(/access_token=([^&]+)/) : null;
        return match ? match[1] : null;
    }

    removeTokenFromURL() {
        window.history.replaceState({}, document.title, window.location.pathname);
    }
}
