import {useMatomo} from "@datapunt/matomo-tracker-react";
import React from "react";
import './NewHome.css';
import TileContainer from "../Tiles/TileContainer";
import SreHomepageLink from "../NewLink/SreHomepageLink";
import chevron from "../../../assets/logos/Chevron.svg";
import NewHomeTabView from "./NewHomeTabView";

function NewHome(props) {
    const {trackPageView, pushInstruction} = useMatomo();
    // Track page view
    trackPageView({
            documentTitle: 'SRE New Homepage', // optional
            href: 'https://sre.ford.com/', // optional
            customDimensions: [
                {
                    id: 1,
                    value: 'loggedIn',
                },
            ],
        }
    )
    pushInstruction('setUserId', props.cdsid)

    return (
        <div className="App" id="outer-container-home">
            <h1 className={"title"}>Build Ford-Tough Apps</h1>
            <h4 className={"subtitle"}>Improve the reliability of your applications with Site Reliability Engineering tools.</h4>
            <TileContainer verboseMode={props.verboseMode} />
            <SreHomepageLink href={"#godown"} className={"go-down-button"}>
                <img src={chevron} alt={"Go Down Chevron"} className={"go-down-image"}/>
            </SreHomepageLink>
            <NewHomeTabView/>

        </div>
    );
}

export default NewHome
