import React from 'react';
import {Nav, Navbar, NavDropdown} from "react-bootstrap";

import './NavBarMenu.scss';

const NavBarMenu = () => {
    return (
        <Navbar className={"nav-bar-container"} variant="dark" expand="lg">
                <Nav className={"nav-bar"}>
                    <NavDropdown renderMenuOnMount={true} title="Tools" id="products-dropdown" className={"nav-item"}>
                        <div className={"menu-dropdown"}>
                            <NavDropdown.Item href={window.location.origin + "/splunk-oncall"} className={"drop-down-item"}>Splunk On-Call</NavDropdown.Item>
                            {/* <NavDropdown.Item href={window.location.origin + "/observability-platform/logging"} className={"drop-down-item"}>SRE Logging</NavDropdown.Item> */}
                            <NavDropdown.Item href={window.location.origin + "/healthnut"} className={"drop-down-item"}>HealthNut</NavDropdown.Item>
                            <NavDropdown.Item href={window.location.origin + "/observability-platform/metrics"} className={"drop-down-item"}>Observability Platform</NavDropdown.Item>
                            {/* <NavDropdown.Item href={window.location.origin + "/observability-platform/metrics"} className={"drop-down-item"}>SRE Metrics</NavDropdown.Item> */}
                            <NavDropdown.Item href={"https://orgs.sre.ford.com"} target={"_blank"} className={"drop-down-item"}>Grafana Org Manager</NavDropdown.Item>
                            <NavDropdown.Item href={window.location.origin + "/slo"} className={"drop-down-item"}>SLO/SLI</NavDropdown.Item>
                            <NavDropdown.Item href={window.location.origin + "/fam"} className={"drop-down-item"}>FAM</NavDropdown.Item>
                            {/* <NavDropdown.Item href={window.location.origin + "/renovate"} className={"drop-down-item"}>Renovate Randy</NavDropdown.Item> */}
                            <NavDropdown.Item href={window.location.origin + "/observability-platform/tracing"} className={"drop-down-item"}>Tracing</NavDropdown.Item>
                        </div>
                       </NavDropdown>
                    <Nav.Link href="/feedback" id={"feedback"} className={"nav-item"}>Feedback</Nav.Link>
                </Nav>
        </Navbar>
    );
};

export default NavBarMenu;
