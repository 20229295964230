import React from "react";
import Collapsible from "react-collapsible";
import LinkRenderer from "../LinkRenderer";

export default function CollapsibleMenu(props) {
    let renderLinks = () => {
        return props.links.map(link => {
            return LinkRenderer.renderLinks({href: link.href, children: link.text})
        })
    }

    return (
        <Collapsible trigger={props.title}>
            {renderLinks()}
            {props.children}
        </Collapsible>
    )
}