

export default function getAdfsUrl(hostName) {
        return constructConfig(hostName).adfsUrl;

}

export function constructConfig(hostName) {
                             // https://corp.sts.ford.com/adfs/oauth2/authorize?client_id=urn%3Ahealthnut.ford.com%3Aclientid%3Aweb_healthnut%3Aprod&redirect_uri=https%3A%2F%2Fhealthnut.ford.com%2Fauth%2Fauth&response_type=token&resource=urn:healthnut.ford.com:resource:web_healthnut:prod
        const prodAdfsUrl = "https://corp.sts.ford.com/adfs/oauth2/authorize?client_id=urn:sre.ford.com:clientid:web_srehomepage:prod&resource=urn:sre.ford.com:resource:web_srehomepage:prod&response_type=token&redirect_uri=";
        const nonProdAdfsUrl =  "https://corpqa.sts.ford.com/adfs/oauth2/authorize?client_id=urn:sre.ford.com:clientid:web_srehomepage:dev&resource=urn:sre.ford.com:resource:web_srehomepage:dev&response_type=token&redirect_uri=";
        if (hostName.includes("localhost")) {
                return {
                        baseApiUrl: "http://localhost:8080",
                        adfsUrl: nonProdAdfsUrl,
                        tracing: true
                };
        }
        if (hostName.includes("sre.ford.com")) {
                return {
                        baseApiUrl: "https://sre.ford.com",
                        adfsUrl: prodAdfsUrl,
                        tracing: false
                };
        }
        if (hostName.includes("pd01i")) {
                return {
                        baseApiUrl: "https://sre-homepage-api-prod.apps.pd01i.edc1.cf.ford.com",
                        adfsUrl: prodAdfsUrl,
                        tracing: false
                };
        }
        if (hostName.includes("pp01i")) {
                return {
                        baseApiUrl: "https://sre-homepage-api-dev.apps.pp01i.edc1.cf.ford.com",
                        adfsUrl: nonProdAdfsUrl,
                        tracing: false
                };
        }
}
