import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import '../App.css';

export default function Header() {

    return (
        
        <div className={"header-content"} id={"top"}>
            <AppBar className={"app-header"} position="static" style={{backgroundColor: '#26282F'}}>
                <Toolbar>
                    <a href={"/"} data-testid={"title"} className={"title-link"}>
                        <h1 className={"header"}>Site Reliability Engineering</h1>
                    </a>
                </Toolbar>
            </AppBar>
        </div>
    );
}