import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import UserAuthenticationHandler from "./UserAuthenticationHandler";
import {MatomoProvider, createInstance} from '@datapunt/matomo-tracker-react';
import {BrowserRouter} from "react-router-dom";

const instance = createInstance({
    urlBase: 'https://matomo.sre.ford.com',
    siteId: 3,
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: { // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 10 // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    configurations: { // optional, default value: {}
        // any valid matomo configuration, all below are optional
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: 'POST'
    }
})

ReactDOM.render(
    <React.StrictMode>
        <MatomoProvider value={instance}>
            <BrowserRouter>
                <App authenticationHandler={new UserAuthenticationHandler()}/>
            </BrowserRouter>
        </MatomoProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
