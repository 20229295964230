import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import CollapsibleMenu from "../CollapsibleMenu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./Capstone.css"

const CapstoneContentRow = (props) => {
    const [checked, setChecked] = useState(false)
    const [rotateChevron, setRotateChevron] = useState(false);

    const handleRotate = () => {
        setRotateChevron(!rotateChevron);
    }

    const handleChecked = () => setChecked(!checked);

    const rotate = rotateChevron ? "rotate(0deg)" : "rotate(-90deg)"

    return (
        <Grid container
              direction="row"
              className={'Capstone-content-row'}
              spacing={1}
        >
            <FormControlLabel className={"checkboxes"}
                              control={<Checkbox checked={checked} onChange={handleChecked} color="primary"/>}
                              label={<h2>{props.label}</h2>}
            />
            <CollapsibleMenu
                open={props.open}
                title={(
                    <div onClick={handleRotate}
                         onDoubleClick={handleRotate}
                         style={{display: "flex", color: "white", fontStyle: "italic"}}
                    >
                        <h3>Details</h3>
                        <ExpandMoreIcon className={"capstone-chevron"}
                                        style={{transform: rotate, transition: "all 0.2s linear"}}
                        />
                    </div>
                )}
                links={[]}
            >
                <div className={"Capstone-Instructions"}>
                    {props.children}
                </div>
            </CollapsibleMenu>

        </Grid>
    );
}

export default CapstoneContentRow;