import React from 'react';
import LinkRenderer from "../../../LinkRenderer";
import StyleButton from "../../Buttons/StyleButton";

const TileButton = (props) => {
    const button = <StyleButton text={props.text} onClick={props.onClick} className={"tile-button"} />
    return LinkRenderer.renderLinks({href: props.href, children: button, className: props.className})
};

export default TileButton;

