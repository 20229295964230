import React from "react";
import Grid from "@material-ui/core/Grid";
import CapstoneContentRow from "./CapstoneContentRow";
import "./Capstone.css"

export default function CapstonePage() {

    return (
        <div className={"capstone-page-container"}>
            <div className={'main-content'}>

                <div style={{textAlign: "center", color: "white", border:"2px #12D5D7 solid", borderRadius:"10px",width:"80vw", margin:"0 auto", padding:"10px", marginTop:"10px"}}>
                    <h1 style={{color: "#12D5D7"}}>Welcome to the SRE Capstone Challenge!</h1>
                    <h2>This challenge will take you through the process of adding elements of Site Reliability
                        Engineering to a brand new app.</h2>
                    <br/>
                    <h3>The checklist is for your own tracking purposes - all checkmarks will reset if you refresh this
                        page.</h3>
                    <h3>Please feel free to work through this checklist at your own pace.</h3>
                    <br/>
                    <h3>Good luck! If you have any feedback regarding this challenge, please feel free to let us know in
                        our Community Forums:</h3>
                    <h3> Slack: #sre-community   &nbsp;&nbsp;&nbsp;  | &nbsp;&nbsp;&nbsp;    Webex: SRE Community -
                        Proprietary</h3>
                </div>
                <br/>
                <br/>
                <Grid container
                      direction="column"
                      className={'Capstone-content-column'}
                      spacing={1}
                >
                    <CapstoneContentRow label={"Prerequisites"}>
                        <h2>GitHub Access</h2>
                        <ul>
                            <li><h3>You can request access via www.accessmgmt.ford.com</h3></li>
                        </ul>
                        <h2>Ford VPN Access</h2>
                        <br/>
                        <h2>Install the cloud Foundry CLI:</h2>
                        <ul>
                            <li><h3>Mac: https://cli.run.pivotal.io/stable?release=macosx64&source=github</h3></li>
                            <li><h3>Windows: https://cli.run.pivotal.io/stable?release=windows64&source=github</h3></li>
                        </ul>
                        <h2>Join our #sre-community channel on Slack or our SRE Community on WebEx</h2>
                        <ul>
                            <li><h3>To join the WebEx space, look under public spaces at www.webexteams.ford.com</h3>
                            </li>
                        </ul>
                        <h3>~If you need help or have questions with any part of this learning capstone, please reach
                            out! We're here to help.</h3>
                    </CapstoneContentRow>

                    <CapstoneContentRow label={"Deploy a Basic App"}>
                        <h2>Go to dcs.ford.com and select either "Spring Boot + Java" or "Spring Boot + Kotlin"</h2>
                        <br/>
                        <h2>Select SRE Observability Platform</h2>
                        <br/>
                        <h3 style={{fontStyle: "italic", color: "white"}}>If you ever want to add Observability platform
                            to an existing app, just add
                            these
                            lines to your existing project:
                            https://github.ford.com/Platform-Enablement/sre_metrics/blob/master/docs/setup.md</h3>
                        <br/>
                        <h2>(Optional) Select Simple REST Controller if you want to call various endpoints on your
                            service
                            to simulate light traffic</h2>
                        <br/>
                        <h2>Click on the Jenkins link to see your app deploy!</h2>
                    </CapstoneContentRow>

                    <CapstoneContentRow label={"See your Application on the Observability Platform"}>
                        <h2>After your application has been deployed, you can immediately see its metrics in the
                            Observability Platform</h2>
                        <br/>
                        <h2>Navigate to observability.sre.ford.com and go to the "4 Golden Signals - Basic"
                            dashboard</h2>
                        <ul>
                            <li><h3>Click on Application Metrics from the links in the middle of the page.</h3></li>
                            <li><h3>Then select "4 Golden Signals - Basic" to open the dashboard.</h3></li>
                        </ul>
                        <h2>Access your application's metrics in "Thanos-Preprod"</h2>
                        <ul>
                            <li><h3>Select "Thanos-Preprod" in the Datasource filter</h3></li>
                            <li><h3>Enter your application's service name in the "Service" filter at the top of the
                                page</h3>
                            </li>
                        </ul>
                        <h3>You won't see much here yet, but you should at least see your memory and CPU usage on the 2
                            left dashboards.</h3>
                    </CapstoneContentRow>

                    <CapstoneContentRow label={"Add HealthNut Monitoring"}>
                        <h3 style={{color: "white"}}> By configuring HealthNut to monitor our health endpoint, we can
                            get alerts when our
                            application enters an unhealthy state. For the purposes of the SRE Capstone, we'll also get
                            some traffic to our service for us to observe.
                        </h3>
                        <br/>
                        <h2>Obtain the health endpoint for your service</h2>
                        <ul>
                            <li><h3>Click on the PCF Apps Manager Login link to get to your newly created PCF Org</h3>
                            </li>
                            <ul>
                                <li style={{listStyleType: "none"}}>If you closed the tab, navigate to
                                    login.sys.pp01.edc1.cf.ford.com
                                </li>
                            </ul>
                            <br/>
                            <li><h3>Click Apps Manager and sign in using the "Ford Motor Company Single Sign-On"
                                link</h3></li>
                            <br/>
                            <li><h3>Click on the org and space you created via DCS</h3></li>
                            <ul>
                                <li style={{listStyleType: "none"}}>The Org usually has your CDSID in the name and the
                                    space contains the "team"
                                </li>
                            </ul>
                            <br/>
                            <li><h3> You can click or copy the "Route" link then add /actuator/health on the end of the
                                url - This is your health endpoint</h3></li>
                            <ul>
                                <li style={{listStyleType: "none"}}>For example:
                                    sre-capstone.apps.pp01i.edc1.cf.ford.com/actuator/health
                                </li>
                            </ul>
                        </ul>
                        <h3 style={{fontStyle: "italic", color: "white"}}>This default health endpoint only covers a
                            minimal set of health information for
                            your application. If you know of other potential issues your application can run into, you
                            should look into adding a custom health indicator to catch these events! Check here for more
                            info: https://www.baeldung.com/spring-boot-health-indicators
                        </h3>
                        <br/>
                        <h2>Navigate to healthnut.ford.com and add your endpoint to HealthNut (VPN Required)</h2>
                        <ul>
                            <li><h3>For this capstone, select either "Get Started" with Slack or WebEx</h3></li>
                            <br/>
                            <li><h3>Make sure you received a welcome message in the channel you registered with</h3>
                            </li>
                            <ul>
                                <li style={{listStyleType: "none"}}>You can always remove this monitor in future from
                                    the "Manage" tab on healthnut.ford.com
                                </li>
                            </ul>
                        </ul>
                    </CapstoneContentRow>

                    <CapstoneContentRow label={"Use the Observability Platform"}>
                        <h2>See the calls HealthNut makes to your service on the Observability Platform</h2>
                        <ul>
                            <li><h3>Navigate back to the "4 Golden Signals - Basic" dashboard</h3></li>
                            <br/>
                            <li><h3>You should be able to observe the calls HealthNut is making to your service for
                                Health
                                Monitoring</h3></li>
                        </ul>
                        <br/>
                        <h3 style={{fontStyle: "italic", color: "white"}}>You might also see a 404 from when you clicked
                            on the route link in PCF. You can use
                            this dashboard and all other dashboards in the "Application Metrics" section of the
                            observability site to help debug your application and detect failures.</h3>
                        <br/>
                        <h3 style={{fontStyle: "italic", color: "white"}}>The 4 Golden Signals are an excellent starting
                            point for your Service Level
                            Indicators (SLIs), but the best SLIs are those that are unique to your service. At what
                            threshold of each of these SLIs do you think your users would begin to see issues with your
                            service? When you encounter an incident in production, you can check these SLIs to help you
                            answer this question and determine what your service level objectives (SLOs) should be.</h3>
                        <br/>
                        <h3>~In the future, you can observe your application's availability from the "4 Golden Signals -
                            Extended" dashboard under "SRE Metrics Advanced Application Telemetry". This data is only
                            available if you have integrated with our SRE Metrics library, which we have already done
                            for this application by selecting "SRE Observability Platform" in Dev/Central/Station.</h3>
                        <br/>
                    </CapstoneContentRow>

                    <CapstoneContentRow label={"Add SRE Logging"}>
                        <h3 style={{color: "white"}}>By sending our log data to the Observability platform, we can
                            visualize our logs in Grafana
                            and create custom dashboards based on any log data we have. We can also add additional data
                            sources to our own Grafana Org to make combined dashboards for all our sources of data.</h3>
                        <br/>
                        <h2>Navigate to orgs.sre.ford.com and create your Grafana Org (VPN Required)</h2>
                        <ul>
                            <li><h3>Create a new org with a name that ends with "-srecapstone", such as
                                "mstefa27-srecapstone"</h3></li>
                            <ul>
                                <li style={{listStyleType: "none"}}>Or, if you plan to use the org for your team, enter
                                    your team name
                                </li>
                            </ul>
                            <br/>
                            <li><h3>Select a data retention policy for your logs. For this capstone project, select 7
                                days. You
                                can always add more logging sources later.</h3></li>
                        </ul>
                        <br/>
                        <h2>Send logs to the Observability Platform using the commands given at the end of org
                            creation</h2>
                        <ul>
                            <li><h3>Ensure you have installed the CF CLI and open a terminal (Use PowerShell on
                                Windows)</h3></li>
                            <br/>
                            <li><h3>First, sign into your free trial space by entering:</h3></li>
                            <ul>
                                <li style={{listStyleType: "none"}}>cf login -a https://api.sys.pp01.edc1.cf.ford.com
                                    --sso
                                </li>
                                <li style={{listStyleType: "none"}}>Get a temporary passcode from the link provided and
                                    enter it to complete login
                                </li>
                                <li style={{listStyleType: "none"}}>Select the space that contains your application</li>
                            </ul>
                            <br/>
                            <li><h3>Now you can enter the commands you see on the orgs.sre.ford.com screen to link up
                                logs with
                                your newly created Grafana Org. Make sure you have the "Internal" commands displayed and
                                replace YOUR-APP-HERE with the name of your application in PCF</h3></li>
                            <br/>
                            <li><h3>Ensure you get an "OK" response for both commands. If you need help with this step,
                                reach
                                out to us on our community channels.</h3></li>
                        </ul>
                        <br/>
                        <h2>Navigate to your Grafana Org on the Observability Platform site and view the "Logs"
                            dashboard</h2>
                        <ul>
                            <li><h3>Head to observability.sre.ford.com and hover over your icon in the lower left
                                corner,
                                then hit the Switch button under "Current Org"</h3>
                            </li>
                            <br/>
                            <li><h3>Click on the Grafana Org you created, such as "mstefa27-srecapstone"</h3></li>
                            <br/>
                            <li><h3>Once in your new org, you can view your logs from the "Logs" dashboard by hovering
                                over
                                the dashboards icon on the sidebar, then clicking on Manage</h3>
                            </li>
                            <br/>
                            <li><h3>Click "Logs" to see the logging dashboard. This may take a few minutes to populate -
                                you
                                can use the auto refresh dropdown in the upper right to refresh for you.</h3>
                            </li>
                        </ul>
                        <br/>
                        <h2>Try using a filter to determine how often your service is being called by HealthNut</h2>
                        <ul>
                            <li><h3>Hint: look for the line with the message: GET "/actuator/health", parameters={"{}"}</h3></li>
                        </ul>
                        <br/>
                        <h3 style={{fontStyle: "italic", color: "white"}}>You can use the graph at the top to determine
                            the relative level of log output by
                            your application. If you see any spikes, upward or downward, they may indicate an issue or
                            event affecting your service. You can click, hold, and release on the timeline to "drill
                            down" to a particular time you suspect may be causing an issue.</h3>
                        <br/>

                        <div style={{width:"50vw", margin: "0 auto", border:"2px #12D5D7 solid", borderRadius:"10px", padding:"10px", color:"white", textAlign:"center"}}>
                            <h1 style={{color:"#12D5D7"}}>Congratulations!</h1>
                            <br/>
                            <h2 >You've just implemented baseline monitoring and observability for your
                                application!</h2>
                        </div>
                    </CapstoneContentRow>
                </Grid>

            </div>
        </div>

    );
}