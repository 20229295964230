import React from 'react';
import ProductPage from "./ProductPage";
import FamTabView from "./FamTabView";
import SplunkOnCallTabView from "./SplunkOnCallTabView";
import HealthnutTabView from "./HealthnutTabView";
import ObservabilityPlatformTabView from "./ObservabilityPlatformTabView";
import RenovateRandyTabView from "./RenovateRandyTabView";
import SLOTabView from "./SLOTabView";
import AlertHeader from "../NewLandingPage/AlertHeader/AlertHeader";

export const FamPage = () => {
    return (
        <div>
                <ProductPage
                            title={"Ford Advisory Management"}
                            subtitle=<AlertHeader severity="error" text="FAM will be shutdown on 5/30/2024. Please use BMC as an alternative."/>
                            buttonText={"Go to BMC"}
                            link={"https://azureford.sharepoint.com/sites/ITAdvisorySite/public?e=1%3A881bbc06f250487186b0f6f7f414d080"}
                            tabView={<FamTabView/>}
                            pageValue={"FamPage"} 
                />
        </div>
    );
};

export const SLOPage = () => {
    return (
        <ProductPage
            title={"SLO/SLI"}
            subtitle={"Take control of your app's reliability with objectives and measurements"}
            buttonText={"Create your SLI"}
            link={"https://orgs.sre.ford.com/sli"}
            tabView={<SLOTabView/>}
            pageValue={"slo"}
        />
    );
};

export const HealthNutPage = () => {
    return (
            <ProductPage
                         title={"HealthNut"}
                         subtitle={"Automate alerting so you know immediately when something is wrong."}
                         buttonText={"Go to HealthNut"}
                         link={"https://healthnut.ford.com"}
                         tabView={<HealthnutTabView/>}
                         pageValue={"Healthnut Page"}
            />
    );
};



export const SplunkOnCallPage = () => {
    return (
        <ProductPage
                     title={"Splunk On-Call"}
                     subtitle={"Splunk On-Call (Formerly VictorOps) is a management tool available in mobility that assists in organizing the Incident Management Process. This page will tell you how to set up the platform, but if you would like assistance the SRE team would be happy to help."}
                     buttonText={"Go to Splunk On-Call"}
                     link={"https://portal.victorops.com/client/ford"}
                     tabView={<SplunkOnCallTabView/>}
                     pageValue={"Splunk On-Call Page"}
        />
    );
};

export const ObservabilityPlatformPage = () => {
    return (
        <ProductPage
                    title={"Observability Platform"}
                    subtitle={`The Observability Platform is a system made up of open source software hosted on
                            Azure to collect and retain metric, logging, and tracing data. After sending your data to our platform
                            via our tools, this data can then be viewed through our Grafana site below.`}
                    buttonText={"Go to The Observability Platform"}
                    link={"https://observability.sre.ford.com"}
                    tabView={<ObservabilityPlatformTabView/>}
                    pageValue={"Observability Platform Page"}
        />
    );
};

export const RenovateRandy = () => {
    return (
        <ProductPage
            title={"Renovate Randy"}
            subtitle={"This application is no longer active"}
        />
    );
};
