import React, {Component, useEffect} from 'react';
import './App.css';
import Header from "./components/Header";
import {Route, Switch,} from "react-router-dom";
import GithubMarkdown from "./components/github-markdown/GithubMarkdown";
import HamburgerMenu from "./components/HamburgerMenu";
import NewHome from "./components/NewLandingPage/NewHome/NewHome";
import jwt_decode from "jwt-decode";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import CapstonePage from "./components/Capstone/CapstonePage";
import Elm from "react-elm-components";
import MyElmComponent from "./components/Elms/ReactFooter.elm"
import NewHeader from "./components/NewLandingPage/NewHeader/NewHeader";
import AlertHeader from "./components/NewLandingPage/AlertHeader/AlertHeader";
import Feedback from "./components/Feedback/Feedback";
import FeedbackService from "./services/FeedbackService";
import {
    SplunkOnCallPage,
    FamPage,
    HealthNutPage,
    ObservabilityPlatformPage,
    RenovateRandy, SLOPage
} from "./components/Product-Pages/ProductPageElements";
import ProductTabIndexService from "./services/ProductTabIndexService";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cdsid: "not found",
            token: undefined,
            verbose: false,
        };
    }

    componentDidMount() {
        const token = this.props.authenticationHandler.getToken();

        if (!token) {
            this.props.authenticationHandler.redirect();
        } else {

            const decoded = jwt_decode(token);
            //unique name is email
            let user = decoded.userid
            let verboseMode = false
            if (user.toUpperCase() === "TKOVACI1"){ user = "Travis: Owner of Fam"}
            if (user.toUpperCase() === "MAHMAD34"){ verboseMode = true}
            this.setState({ token: token, cdsid: user, verbose: verboseMode });
            this.props.authenticationHandler.removeTokenFromURL();
        }
    }

    render() {
        if (!this.props.authenticationHandler.getToken()) {
            return <></>
        }
        const why = ProductTabIndexService.possibleTabs[0];
        const setup = ProductTabIndexService.possibleTabs[1];
        const migration = ProductTabIndexService.possibleTabs[2];
        const metrics = ProductTabIndexService.possibleProducts[0];
        const logging = ProductTabIndexService.possibleProducts[1];
        const tracing = ProductTabIndexService.possibleProducts[2];
        const grafanaTutorials = ProductTabIndexService.possibleProducts[3];

        return (
            <div className={this.state.verbose ? "homepage-all" : ""}>
                <div>
                    <NewHeader verboseMode={this.state.verbose} cdsid={this.state.cdsid}/>
                    <AlertHeader severity="error" text="SRE Metrics is being decommissioned on 10/30 and SRE Logging is being decommissioned on 9/12! Be sure to look at individual product pages for additional information."/>
                    <Switch>
                        <Route exact path='/oldhome' render={(props) => (
                            <Home {...props} cdsid={this.state.cdsid}/>
                        )}/>
                        <Route exact path='/capstone-challenge' render={(props) => (
                            <Capstone {...props} cdsid={this.state.cdsid}/>
                        )}/>
                        <Route exact path='/' render={(props) => (
                            <NewHome {...props} verboseMode={this.state.verbose} cdsid={this.state.cdsid}/>
                        )}/>
                        <Route exact path='/observability-platform/metrics/troubleshooting' render={(props) => (
                            <GithubMarkdown {...props} fileName={"Troubleshooting"}/>
                        )}/>
                        <Route exact path='/observability-platform/metrics/upgrades' render={(props) => (
                            <GithubMarkdown {...props} fileName={"Upgrades"}/>
                        )}/>
                        <Route exact path='/observability-platform/metrics/setup-alerts' render={(props) => (
                            <GithubMarkdown {...props} fileName={"SetupAlerts"}/>
                        )}/>
                        <Route exact path='/observability-platform/metrics/setup-configuration' render={(props) => (
                            <GithubMarkdown {...props} fileName={"SetupConfiguration"}/>
                        )}/>
                        <Route exact path='/observability-platform/metrics/setup-openshift' render={(props) => (
                            <GithubMarkdown {...props} fileName={"SetupOpenshift"}/>
                        )}/>
                        <Route exact path='/observability-platform/custom-health-indicators' render={(props) => (
                            <GithubMarkdown {...props} fileName={"CustomHealthIndicators"}/>
                        )}/>
                        <Route exact path='/observability-platform/metrics/victor-ops' render={(props) => (
                            <GithubMarkdown {...props} fileName={"SplunkOncall"}/>
                        )}/>
                        <Route exact path='/observability-platform/renovate-randy' render={(props) => (
                            <GithubMarkdown {...props} fileName={"SetupRenovateRandy"}/>
                        )}/>

                        <Route exact path={`/observability-platform/:product(${metrics}|${logging}|${tracing}|${grafanaTutorials})/:tab(${why}|${setup}|${migration})?`} component={ObservabilityPlatformPage}/>
                        <Route exact path={`/healthnut/:tab(${why}|${setup})?`} component= {HealthNutPage}/>
                        <Route exact path={`/splunk-oncall/:tab(${why}|${setup})?`} component={SplunkOnCallPage}/>
                        <Route exact path={`/fam/:tab(${why}|${setup})?`} component={FamPage}/>
                        <Route exact path={`/renovate/:tab(${why}|${setup})?`} component={RenovateRandy}/>
                        <Route exact path={`/slo/:tab(${why}|${setup})?`} component={SLOPage}/>
                        <Route exact path='/feedback' render={(props) => (
                            <Feedback {...props} cdsid={this.state.cdsid} feedbackService={FeedbackService} />
                        )} />
                        <Route exact path='/health' component={Health} />
                        <Route component={NotFound} />
                    </Switch>
                </div>
                <footer>
                    <Elm src={MyElmComponent.Elm.ReactFooter}/>
                </footer>
            </div>
        );
    }

}

function Home(props) {
    const {trackPageView, pushInstruction} = useMatomo();
    // Track page view
    trackPageView({
            documentTitle: 'SRE Homepage', // optional
            href: 'https://sre.ford.com', // optional
            customDimensions: [
                {
                    id: 1,
                    value: 'loggedIn',
                },
            ],
        }
    )
    pushInstruction('setUserId', props.cdsid)

    return (
        <div className="App" id="outer-container-home">
            <Header/>
            <HamburgerMenu pageWrapId={"page-wrap-home"} outerContainerId={"outer-container-home"}/>
        </div>
    );
}

function Capstone(props) {
    const {trackPageView, pushInstruction} = useMatomo();
    // Track page view
    trackPageView({
            documentTitle: 'SRE Capstone Challenge', // optional
            href: 'https://sre.ford.com/capstone-challenge', // optional
            customDimensions: [
                {
                    id: 2,
                    value: 'CapstonePage',
                },
            ],
        }
    )
    pushInstruction('setUserId', props.cdsid)

    return <CapstonePage/>
}

function Health() {
    return (
        <div>
            <h3 style={{color: "white"}}>This is one healthy boi.</h3>
        </div>
    );
}

function NotFound() {
    useEffect(() => {
        document.title = window.location.pathname;
    });

    return (
        <div>
            <h3 style={{color: "white"}}>Page Not Found</h3>
        </div>
    )
}

export default App;
