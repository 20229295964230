import {HashLink} from "react-router-hash-link";
import React from "react";

export default class LinkRenderer {

    static renderLinks(props) {
        if (LinkRenderer.isInternalRoute(props.href)) {
           return LinkRenderer.doesNotHaveHashLink(props.href) && !props.href?.includes(".pdf")
                ? <HashLink className={props.className} to={`${props.href}#top`}>{props.children}</HashLink>
                : <HashLink className={props.className} smooth to={props.href}>{props.children}</HashLink>
        }
        return <a className={props.className} href={props.href} rel={"noopener noreferrer"} target={"_blank"}>{props.children}</a>
    }

    static doesNotHaveHashLink(href) {
        return !href?.includes("#");
    }

    static isInternalRoute(href) {
        return (!href?.startsWith("http") || href?.includes(`://${window.location.host}`))
            && !href?.includes(".pdf")
    }
}