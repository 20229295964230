import React from 'react';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import './ProductPages.css';
import img from "../../assets/images/BenefitStar.svg";
import logsImage from "../../assets/images/logs.jpg";
import GithubMarkdown from "../github-markdown/GithubMarkdown";
import customDashboard from "../../assets/images/customDashboard.png";
import AlertHeader from "../NewLandingPage/AlertHeader/AlertHeader";

import {constructConfig} from "../../config";
import ProductTabIndexService from "../../services/ProductTabIndexService";
import {useHistory} from "react-router";


const ObservabilityPlatformTabView = (props) => {

    const history = useHistory();
    const config = constructConfig(window.location.hostname)

    return (
        <div className={"tabs-outer"}>
            <Tabs className={"tabs"} defaultIndex={ProductTabIndexService.getIndexOfProductToDisplay(props.product)}>
                <TabList className={"tab-list"}>
                    <Tab className={"tab"} onClick={() => history.push("/observability-platform/metrics")}><h2>Metrics</h2></Tab>
                    <Tab className={"tab"} onClick={() => history.push("/observability-platform/logging")}><h2>Logging</h2></Tab>
                    <Tab className={"tab"} onClick={() => history.push("/observability-platform/tracing")}><h2>Tracing</h2></Tab>
                    <Tab className={"tab"} onClick={() => history.push("/observability-platform/grafana-tutorials")}><h2>Grafana Tutorials</h2></Tab>
                </TabList>


                <TabPanel className={"spotlight-tab"}>
                    <Tabs className={"sub-tabs"} defaultIndex={ProductTabIndexService.getIndexOfTabToDisplay(props.tab)}>
                        <TabList className={"sub-tab-list"}>
                            <Tab className={"tab"} onClick={() => history.push("/observability-platform/metrics/why")}><h4>Suggested Migration Platforms</h4></Tab>
                            {/* <Tab className={"tab"} onClick={() => history.push("/observability-platform/metrics/setup")}><h4>Set Up SRE Metrics</h4></Tab> */}
                        </TabList>

                        <div className={"tab-content"}>
                            <AlertHeader severity="error" text="Onboarding to SRE Metrics is no longer permitted.  Platform has been deprecated, review below for new solution."/>
                            <TabPanel className={"spotlight-tab"}>
                                <br/>
                                {/* <a href={"https://observability.sre.ford.com/d/dx25mo7Mk/application-dashboards?orgId=1"}
                                   rel={"noopener noreferrer"}
                                   target={"_blank"}
                                   className={"goto-link"}><h3>Go to SRE Metrics</h3></a> */}
                                {/* <br/><br/> */}
                                <h2>Dynatrace</h2>
                                <ul>
                                    <li>Dynatrace will be replacing SRE Metrics for receiving metrics. Onboard your application to Dynatrace by 10/30 and setup observability in Grafana. Reach out to the Dynatrace team on Webex for further support.</li>
                                    <br />
                                    <li>After integrating with Dynatrace, use the Infinity plugin to create a new datasource with your Dynatrace details and configure your Grafana dashbaords accordingly to continue receiving data. Please reach out on the SRE Community Webex Space with questions.</li>
                                    <li><a href={"https://www.webexteams.ford.com/space?r=yp3k"}
                                        rel={"noopener noreferrer"}
                                        target={"_blank"}
                                        className={"goto-link"}><h3>Self-join the Dynatrace Community Space</h3></a></li>
                                </ul>
                                <h2>Instructions to remove SRE Metrics Library</h2>
                                <GithubMarkdown viewInTabView={true} fileName={"SreMetricsRemoval"} />
                                {/* <h2><img src={img} alt={"BenefitStar"}/> 4 Golden Signals</h2>
                                <ul>
                                    <li>- Get Traffic, Availability, Latency, and Transactions per second metrics as
                                        well as hardware saturation and health status out of the box.
                                    </li>
                                </ul>
                                <h2><img src={img} alt={"BenefitStar"}/> Application Health</h2>
                                <ul>
                                    <li>- Track your app's availability and health indicators over time.</li>
                                </ul>
                                <h2><img src={img} alt={"BenefitStar"}/> Easy Onboarding</h2>
                                <ul>
                                    <li>- Just add our SRE Metrics library and add an application name in your
                                        configuration.
                                    </li>
                                </ul>
                                <h2><img src={img} alt={"BenefitStar"}/> Add Custom Metrics to Your Observability View
                                </h2>
                                <ul>
                                    <li>- The SRE metrics library will collect any metrics you expose with your
                                        Prometheus endpoint.
                                    </li>
                                </ul> */}
                            </TabPanel>

                            {/* <TabPanel>
                                <div>
                                    <GithubMarkdown viewInTabView={true} fileName={"SreMetrics"}/>
                                </div>
                            </TabPanel> */}
                        </div>
                    </Tabs>

                </TabPanel>

                <TabPanel>
                    <Tabs className={"sub-tabs"} defaultIndex={ProductTabIndexService.getIndexOfTabToDisplay(props.tab)}>
                        <TabList className={"sub-tab-list"}>
                            <Tab className={"tab"} onClick={() => history.push("/observability-platform/logging/setup")}><h4>Suggested Migration Platforms</h4></Tab>
                        </TabList>

                        <div className={"tab-content"}>
                            <AlertHeader severity="error" text="Onboarding to SRE Logging is no longer permitted.  Platform has been deprecated, review below for new solution."/>
                            <TabPanel className={"spotlight-tab"}>
                                    {/* <GithubMarkdown viewInTabView={true} fileName={"SetupSreLogging"}/> */}
                                <br/>
                                <h2>Splunk</h2>
                                <ul>
                                    <li>Splunk will be replacing SRE Logging. Integrate your application with Splunk by using the self service tool in PBC. Reach out to the Splunk team on Webex for further support.</li>
                                    <li><a href={"https://pbc.ford.com/"}
                                        rel={"noopener noreferrer"}
                                        target={"_blank"}
                                        className={"goto-link"}><h3>Onboard to Splunk here</h3></a>
                                    <a href={"https://www.webexteams.ford.com/space?r=jbai"}
                                        rel={"noopener noreferrer"}
                                        target={"_blank"}
                                        className={"goto-link"}><h3>Splunk Webex Teams Space</h3></a></li>
                                </ul>
                            </TabPanel>

                        </div>
                    </Tabs>
                </TabPanel>

                {/* <TabPanel>
                    {config.tracing === true
                        ?
                    <Tabs className={"sub-tabs"} defaultIndex={ProductTabIndexService.getIndexOfTabToDisplay(props.tab)}>
                        <TabList className={"sub-tab-list"}>
                            <Tab className={"tab"} onClick={() => history.push("/observability-platform/tracing/why")}><h4>Why use SRE Tracing?</h4></Tab>
                            <Tab className={"tab"} onClick={() => history.push("/observability-platform/tracing/setup")}><h4>Set Up SRE Tracing</h4></Tab>
                        </TabList>
                        {<div className={"tab-content"}>
                            <TabPanel className={"spotlight-tab"}>
                                <br/>
                                {<a href={"https://observability.sre.ford.com/d/dx25mo7Mk/application-dashboards?orgId=1"}
                                  rel={"noopener noreferrer"}
                                  target={"_blank"}
                                  className={"goto-link"}><h3>Go to SRE Tracing</h3></a>}
                                <br/><br/>
                                <h2><img src={img} alt={"BenefitStar"}/> Currently Supported Platforms</h2>
                                <ul>
                                    <li>- Java/Kotlin on PCF
                                    </li>
                                </ul>
                                <h2><img src={img} alt={"BenefitStar"}/> Current Features</h2>
                                <ul>
                                    <li>- Basic application tracing
                                    </li>
                                    <li>- Single pane of glass within the Observability Cluster
                                    </li>
                                </ul>
                                <h2><img src={img} alt={"BenefitStar"}/> Planned Features</h2>
                                <ul>
                                    <li>- Auto-generated interaction map, based upon tracing information
                                    </li>
                                    <li>- Search
                                    </li>
                                </ul>
                                <h2><img src={img} alt={"BenefitStar"}/> Cost to teams</h2>
                                <ul>
                                    <li>- As with all of our tools, there is no cost passed on to teams
                                        using the
                                        platform.
                                    </li>
                                </ul>
                                <h2><img src={img} alt={"BenefitStar"}/> How to get involved in the Testing
                                    Phases</h2>
                                <ul>
                                    <li>- Reach out to the SRE team in our Community channels (Slack OR
                                        Webex Teams),
                                        and notify us of your interest.
                                    </li>
                                </ul>
                            </TabPanel>

                            <TabPanel>
                                <div>
                                    <GithubMarkdown viewInTabView={true} fileName={"SreTracing"}/>
                                </div>
                            </TabPanel>
                        
                        </div>}
                    </Tabs>
                        :
                        <>
                            <br/>
                            <h1>Tracing is coming soon to the Observability Platform!</h1>
                            <br/>
                            <h2>Be sure to come back here once it has been released.</h2>
                        </>
                    }
                </TabPanel> */}
                <TabPanel className={"spotlight-tab"}>
                    <Tabs className={"sub-tabs"} defaultIndex={ProductTabIndexService.getIndexOfTabToDisplay(props.tab)}>
                        <TabList className={"sub-tab-list"}>
                            <Tab className={"tab"} onClick={() => history.push("/observability-platform/metrics/why")}><h4>Suggested Migration Platforms</h4></Tab>
                            {/* <Tab className={"tab"} onClick={() => history.push("/observability-platform/metrics/setup")}><h4>Set Up SRE Metrics</h4></Tab> */}
                        </TabList>

                        <div className={"tab-content"}>
                            <AlertHeader severity="error" text="Dynatrace will be replacing all previous tracing platforms." />
                            <TabPanel className={"spotlight-tab"}>
                                <br />
                                {/* <a href={"https://observability.sre.ford.com/d/dx25mo7Mk/application-dashboards?orgId=1"}
                                   rel={"noopener noreferrer"}
                                   target={"_blank"}
                                   className={"goto-link"}><h3>Go to SRE Metrics</h3></a> */}
                                {/* <br/><br/> */}
                                <h2>Dynatrace</h2>
                                <ul>
                                    <li>Dynatrace will be replacing all previous tracing platforms. Onboard your application to Dynatrace by 9/12. Reach out to the Dynatrace team on Webex for further support.</li>
                                    <br />
                                    <li><a href={"https://www.webexteams.ford.com/space?r=yp3k"}
                                        rel={"noopener noreferrer"}
                                        target={"_blank"}
                                        className={"goto-link"}><h3>Self-join the Dynatrace Community Space</h3></a></li>
                                </ul>
                                {/* <h2><img src={img} alt={"BenefitStar"}/> 4 Golden Signals</h2>
                                <ul>
                                    <li>- Get Traffic, Availability, Latency, and Transactions per second metrics as
                                        well as hardware saturation and health status out of the box.
                                    </li>
                                </ul>
                                <h2><img src={img} alt={"BenefitStar"}/> Application Health</h2>
                                <ul>
                                    <li>- Track your app's availability and health indicators over time.</li>
                                </ul>
                                <h2><img src={img} alt={"BenefitStar"}/> Easy Onboarding</h2>
                                <ul>
                                    <li>- Just add our SRE Metrics library and add an application name in your
                                        configuration.
                                    </li>
                                </ul>
                                <h2><img src={img} alt={"BenefitStar"}/> Add Custom Metrics to Your Observability View
                                </h2>
                                <ul>
                                    <li>- The SRE metrics library will collect any metrics you expose with your
                                        Prometheus endpoint.
                                    </li>
                                </ul> */}
                            </TabPanel>

                            {/* <TabPanel>
                                <div>
                                    <GithubMarkdown viewInTabView={true} fileName={"SreMetrics"}/>
                                </div>
                            </TabPanel> */}
                        </div>
                    </Tabs>

                </TabPanel>

                <TabPanel>
                    <Tabs className={"sub-tabs"} defaultIndex={ProductTabIndexService.getIndexOfTabToDisplay(props.tab)}>
                        <TabList className={"sub-tab-list"}>
                            <Tab className={"tab"}></Tab>
                        </TabList>

                        <div className={"tab-content"}>
                            <TabPanel className={"spotlight-tab"}>
                                <h2>Copy A Dashboard in Grafana</h2>
                                <ul>
                                    <li>The SRE Main Org in Grafana contains numerous dashboards and panels that have been preconfigured. Copy any of these dashboards to an individually managed org to view intricate queries and visualizations for your data.</li>
                                    <br />
                                    <li>Follow the link below to watch a video tutorial on how to copy a dashboard. Instructions begin around the 12:00 mark in the video.</li>
                                    <li><a href={"https://videosat.ford.com/#/playlist/3114a621-49d4-440a-8910-fa04c34ed93f/videos/?videoId=e272ea69-95d6-4441-978e-1be1ad2a29dd"}
                                        rel={"noopener noreferrer"}
                                        target={"_blank"}
                                        className={"goto-link"}><h3>Copying a dashboard video tutorial</h3></a></li>
                                </ul>
                                {/* <GithubMarkdown viewInTabView={true} fileName={"CopyADashboard"}/> */}
                            </TabPanel>
                        </div>
                    </Tabs>

                </TabPanel>

            </Tabs>
        </div>
    );
};

ObservabilityPlatformTabView.defaultProps = {
    tab: "why",
    product: "metrics"
};

export default ObservabilityPlatformTabView